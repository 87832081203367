<template>
  <div class="w-full mb-8">
    I want to scan...
  </div>

  <div class="flex flex-row justify-around items-start mb-12">
    <div class="flex flex-col items-center justify-center gap-3">
      <div
        class="flex flex-col border-2 rounded w-56 h-56 p-5 gap-6 items-center justify-center cursor-pointer"
        :class="{
          'border-gray-500 text-black': selectedScanType !== 'Web Application',
          'bg-blue-300 border-blue-400 text-blue-50': selectedScanType === 'Web Application'
        }"
        @click="onChangeSelectedScanType('Web Application')"
      >
        <div class="flex">
          <WebAppIcon />
        </div>

        <div class="flex text-center">
          <span>Web Application</span>
        </div>
      </div>

      <div class="flex text-left w-64">
        <span class="text-sm">Choose if you want to scan a web appin a web browser.</span>
      </div>

      <div class="flex flex-col gap-1">
        <div class="flex flex-row items-center justify-center gap-2">
          <div class="w-1.5 h-1.5 bg-black rounded-full" />
          <span class="text-sm">Multi Page Application</span>
        </div>

        <div class="flex flex-row items-center justify-center gap-2">
          <div class="w-1.5 h-1.5 bg-black rounded-full" />
          <span class="text-sm">Javascript Application</span>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center gap-3">
      <div
        class="flex flex-col w-56 h-56 border-2 rounded p-5 gap-6 items-center justify-center cursor-pointer"
        :class="{
          'border-gray-500 text-black': selectedScanType !== 'Api', 
          'bg-blue-300 border-blue-400 text-blue-50': selectedScanType === 'Api'
        }"
        @click="onChangeSelectedScanType('Api')"
      >
        <div class="flex mt-3">
          <ApiIcon />
        </div>

        <div class="flex text-center">
          <span>Application Programming Interface</span>
        </div>
      </div>

      <div class="flex w-64">
        <span class="text-sm">Choose if you want to scan an application programming interface.</span>
      </div>

      <div class="flex flex-col gap-1">
        <div class="flex flex-row items-center gap-2">
          <div class="w-1.5 h-1.5 bg-black rounded-full" />
          <span class="text-sm">REST API</span>
        </div>

        <div class="flex flex-row items-center gap-2">
          <div class="w-1.5 h-1.5 bg-black rounded-full" />
          <span class="text-sm">Microservice Architecture</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebAppIcon from '@/components/icons/WebAppIcon.vue'
import ApiIcon from '@/components/icons/ApiIcon.vue'

export default {
  components: {
    WebAppIcon,
    ApiIcon
  },

  props: {
    scanType: {
      type: String,
      required: true
    }
  },

  emits: ['changeSelectedScanType'],

  data: () => ({
    selectedScanType: '',
  }),

  watch: {
    selectedScanType(nw) {
      this.$emit('changeSelectedScanType', nw);
    },
  },

  mounted() {
    this.selectedScanType = this.scanType;
  },


  methods: {
    onChangeSelectedScanType(payload) {
      this.selectedScanType = payload;
    },
  }
}
</script>