<template>
  <div class="w-full mb-8">
    My web app type is...
  </div>

  <div class="flex flex-row justify-around items-start mb-12">
    <div class="flex flex-col items-center justify-center gap-3">
      <div
        class="flex flex-col border-2 rounded w-56 h-56 p-5 gap-6 items-center justify-center"
        :class="{
          'border-gray-500 text-black': selectedScanSubType !== 'Javascript Application',
          'bg-blue-300 border-blue-400 text-blue-50': selectedScanSubType === 'Javascript Application'
        }"
        @clSubick="onChangeSelectedScanType('Javascript Application')"
      >
        <div class="flex">
          <WebAppIcon />
        </div>

        <div class="flex text-center">
          <span>JavaScript Application</span>
        </div>
      </div>

      <div class="flex text-left w-64">
        <span class="text-sm">Choose if you want to scan a web appin a web browser.</span>
      </div>

      <div class="flex w-full justify-start items-start gap-1">
        <div class="flex gap-2">
          <ul class="list-disc ml-7">
            <li> Angular </li>
            <li> React </li>
            <li> Vue </li>
            <li> JQuery </li>
            <li> Or Similar </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center gap-3">
      <div
        class="flex flex-col w-56 h-56 border-2 rounded p-5 gap-6 items-center justify-center"
        :class="{
          'border-gray-500 text-black': selectedScanSubType !== 'Multi Page Application',
          'bg-blue-300 border-blue-400 text-blue-50': selectedScanSubType === 'Multi Page Application'
        }"
        @clSubick="onChangeSelectedScanType('Multi Page Application')"
      >
        <div class="flex mt-3">
          <ApiIcon />
        </div>

        <div class="flex text-center">
          <span>Mullti Page Application</span>
        </div>
      </div>

      <div class="flex w-64">
        <span class="text-sm">Choose if you want to scan an application programming interface.</span>
      </div>

      <div class="flex w-full justify-start items-start gap-1">
        <div class="flex gap-2">
          <ul class="list-disc ml-7">
            <li> HTML </li>

            <li> JSP </li>

            <li> Or Similar </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebAppIcon from '@/components/icons/WebAppIcon.vue'
import ApiIcon from '@/components/icons/ApiIcon.vue'

export default {
  components: {
    WebAppIcon,
    ApiIcon
  },

  props: {
    scanSubType: {
      type: String,
      required: true
    }
  },

  emits: ['changeSelectedScanSubType'],

  data: () => ({
    selectedScanSubType: '',
  }),

  watch: {
    selectedScanType(nw) {
      this.$emit('changeSelectedScanSubType', nw);
    },
  },

  mounted() {
    this.selectedScanSubType = this.scanSubType;
  },


  methods: {
    onChangeSelectedScanSubType(payload) {
      this.selectedScanSubType = payload;
    },
  }
}
</script>