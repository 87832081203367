<template>
  <div class="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
    <label
      for="name"
      class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
    >{{ label }}</label>

    <input
      :value="value"
      type="text"
      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
      :placeholder="placeholder"
      @input="selam"
    >
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: ''
    }
  },

  emits: ['input'],

  methods: {
    selam(val) {
      this.$emit('input', val);
    }
  },
}
</script>