<template>
  <svg
    width="88"
    height="88"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_169_26458)">
      <path
        d="M78.8333 0H9.16667C6.73552 0 4.40394 0.965771 2.68485 2.68485C0.965771 4.40394 0 6.73552 0 9.16667L0 78.8333C0 80.0371 0.237103 81.2291 0.697771 82.3413C1.15844 83.4534 1.83365 84.4639 2.68485 85.3151C3.53606 86.1664 4.54658 86.8416 5.65874 87.3022C6.77089 87.7629 7.96288 88 9.16667 88H78.8333C80.0371 88 81.2291 87.7629 82.3413 87.3022C83.4534 86.8416 84.4639 86.1664 85.3151 85.3151C86.1664 84.4639 86.8416 83.4534 87.3022 82.3413C87.7629 81.2291 88 80.0371 88 78.8333V9.16667C88 7.96288 87.7629 6.77089 87.3022 5.65874C86.8416 4.54658 86.1664 3.53606 85.3151 2.68485C84.4639 1.83365 83.4534 1.15844 82.3413 0.697771C81.2291 0.237103 80.0371 0 78.8333 0V0ZM9.16667 3.66667H78.8333C80.292 3.66667 81.691 4.24613 82.7224 5.27758C83.7539 6.30903 84.3333 7.70798 84.3333 9.16667V14.6667H3.66667V9.16667C3.66667 7.70798 4.24613 6.30903 5.27758 5.27758C6.30903 4.24613 7.70798 3.66667 9.16667 3.66667ZM78.8333 84.3333H9.16667C7.70798 84.3333 6.30903 83.7539 5.27758 82.7224C4.24613 81.691 3.66667 80.292 3.66667 78.8333V18.3333H84.3333V78.8333C84.3333 80.292 83.7539 81.691 82.7224 82.7224C81.691 83.7539 80.292 84.3333 78.8333 84.3333Z"
        fill="currentColor"
      />

      <path
        d="M25.7363 48.151L32.3778 30.717L39.0193 48.151"
        stroke="currentColor"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M28.2266 42.3396H36.5285"
        stroke="currentColor"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M42.3398 48.1509V31.5471H51.4719V38.1886H42.3398"
        stroke="currentColor"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M53.9619 31.5471H62.2638"
        stroke="currentColor"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M58.1133 31.5471V47.3207"
        stroke="currentColor"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M53.9619 47.3208H62.2638"
        stroke="currentColor"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M82.6883 85.5094C84.3172 83.1714 84.9793 80.3674 84.5528 77.6134C84.1262 74.8594 82.6398 72.3407 80.3669 70.5206C78.094 68.7005 75.1875 67.7015 72.1823 67.7074C69.1771 67.7134 66.2753 68.7238 64.0108 70.5528C63.851 68.7407 63.1741 67.0005 62.0493 65.5101C60.9244 64.0198 59.3921 62.8328 57.609 62.0708C55.826 61.3087 53.8563 60.9989 51.9014 61.173C49.9465 61.3471 48.0767 61.9989 46.4833 63.0617C45.2219 61.2861 43.5036 59.8284 41.4829 58.8197C39.4623 57.811 37.2026 57.283 34.9069 57.283C32.6112 57.283 30.3515 57.811 28.3308 58.8197C26.3102 59.8284 24.5919 61.2861 23.3305 63.0617C21.9655 62.5495 20.4925 62.3317 19.0231 62.425C17.5536 62.5182 16.1265 62.9201 14.8498 63.6C13.5732 64.28 12.4806 65.2202 11.6549 66.3493C10.8292 67.4784 10.2921 68.7668 10.0844 70.1167H9.97357C7.76872 70.1167 5.65417 70.9276 4.0951 72.3709C2.53603 73.8143 1.66016 75.7719 1.66016 77.813C1.66016 79.8542 2.53603 81.8118 4.0951 83.2552C5.65417 84.6985 7.76872 85.5094 9.97357 85.5094H82.6883Z"
        stroke="currentColor"
        stroke-width="3"
        stroke-linejoin="round"
      />
      <path
        d="M77.1149 10.6929C77.3271 10.841 77.5682 10.9425 77.8224 10.9907C78.0766 11.0389 78.3381 11.0328 78.5897 10.9727C78.8414 10.9126 79.0774 10.8 79.2824 10.6422C79.4874 10.4843 79.6567 10.2849 79.7792 10.057C79.9016 9.82908 79.9744 9.57783 79.9928 9.31977C80.0112 9.06171 79.9747 8.80268 79.8858 8.5597C79.797 8.31673 79.6577 8.09531 79.4771 7.91002C79.2966 7.72472 79.0788 7.57974 78.8383 7.4846C78.4204 7.31935 77.9563 7.31333 77.5343 7.4677C77.1123 7.62206 76.7616 7.92606 76.549 8.32194C76.3363 8.71782 76.2765 9.17801 76.3808 9.61511C76.4852 10.0522 76.7464 10.4358 77.1149 10.6929ZM72.6416 9.17127C72.6416 8.68503 72.4484 8.21872 72.1046 7.8749C71.7608 7.53109 71.2945 7.33793 70.8083 7.33793C70.322 7.33793 69.8557 7.53109 69.5119 7.8749C69.1681 8.21872 68.9749 8.68503 68.9749 9.17127C68.9749 9.6575 69.1681 10.1238 69.5119 10.4676C69.8557 10.8114 70.322 11.0046 70.8083 11.0046C71.2945 11.0046 71.7608 10.8114 72.1046 10.4676C72.4484 10.1238 72.6416 9.6575 72.6416 9.17127ZM62.7783 10.8579C63.1698 11.0195 63.6053 11.0404 64.0105 10.9173C64.4158 10.7941 64.766 10.5344 65.0014 10.1823C65.2369 9.83015 65.3431 9.40736 65.3022 8.98576C65.2612 8.56417 65.0755 8.16977 64.7766 7.8696C62.3383 5.90793 60.2483 9.88627 62.7783 10.8579ZM8.47493 11.0046H41.4749C41.9612 11.0046 42.4275 10.8114 42.7713 10.4676C43.1151 10.1238 43.3083 9.6575 43.3083 9.17127C43.3083 8.68503 43.1151 8.21872 42.7713 7.8749C42.4275 7.53109 41.9612 7.33793 41.4749 7.33793H8.47493C7.9887 7.33793 7.52239 7.53109 7.17857 7.8749C6.83476 8.21872 6.6416 8.68503 6.6416 9.17127C6.6416 9.6575 6.83476 10.1238 7.17857 10.4676C7.52239 10.8114 7.9887 11.0046 8.47493 11.0046Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>