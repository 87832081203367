<template>
  <div
    class="flex flex-col gap-2 mb-12"
  >
    <div>
      <Card class="flex flex-col bg-gray-100">
        <template #title>
          Tip
        </template>

        <template #content>
          You have selected a full scan, which will run load intensive tests and submit large amounts of attack strings to your application. Please make sure that it is a test or staging system.
        </template>
      </Card>
    </div>

    <div>
      <div class="flex w-full flex-row justify-between">
        <div />
        <div class="flex flex-row gap-40">
          <div class="flex flex-col items-start justify-start gap-3">
            <div
              class="flex flex-col border-2 rounded w-56 h-56 p-5 gap-6 items-center justify-center cursor-pointer"
              :class="{
                'border-gray-500 text-black': selectedScanSubType !== 'Javascript Application',
                'bg-blue-300 border-blue-400 text-blue-50': selectedScanSubType === 'Javascript Application'
              }"
              @click="onChangeSelectedScanSubType('Javascript Application')"
            >
              <div class="flex">
                <WebAppIcon />
              </div>

              <div class="text-center">
                JavaScript Application
              </div>
            </div>

            <div
              v-for="scope in jsQuickScanScopeList"
              :key="scope.title"
              class="flex flex-row w-full justify-start"
            >
              <div
                class="group flex flex-row gap-2 cursor-pointer"
                @click="scope.isEnabled = !scope.isEnabled"
              >
                <div class="block group-hover:hidden">
                  <CheckIcon
                    v-if="scope.isEnabled"
                    class="h-5 w-5"
                    aria-hidden="true"
                  />

                  <XIcon
                    v-else
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </div>

                <div class="hidden group-hover:block">
                  <CheckIcon
                    v-if="!scope.isEnabled"
                    class="h-5 w-5"
                    aria-hidden="true"
                  />

                  <XIcon
                    v-else
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </div>

                <div v-text="scope.title" />
              </div>
            </div>
          </div>

          <div class="flex flex-col items-start justify-start gap-3">
            <div
              class="flex flex-col border-2 rounded w-56 h-56 p-5 gap-6 items-center justify-center cursor-pointer"
              :class="{
                'border-gray-500 text-black': selectedScanSubType !== 'Api',
                'bg-blue-300 border-blue-400 text-blue-50': selectedScanSubType === 'Api'
              }"
              @click="onChangeSelectedScanSubType('Api')"
            >
              <div class="flex">
                <WebAppIcon />
              </div>

              <div class="flex text-center">
                Api
              </div>
            </div>

            <div
              v-for="scope in apiQuickScanScopeList"
              :key="scope.title"
              class="flex flex-row w-full justify-start"
            >
              <div
                class="group flex flex-row gap-2 cursor-pointer"
                @click="scope.isEnabled = !scope.isEnabled"
              >
                <div class="block group-hover:hidden">
                  <CheckIcon
                    v-if="scope.isEnabled"
                    class="h-5 w-5"
                    aria-hidden="true"
                  />

                  <XIcon
                    v-else
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </div>

                <div class="hidden group-hover:block">
                  <CheckIcon
                    v-if="!scope.isEnabled"
                    class="h-5 w-5"
                    aria-hidden="true"
                  />

                  <XIcon
                    v-else
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </div>

                <div v-text="scope.title" />
              </div>
            </div>
          </div>
        </div>
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, XIcon } from '@heroicons/vue/solid'
import WebAppIcon from '@/components/icons/WebAppIcon.vue'
import Card from '@/components/Card.vue'

export default {
  components: {
    CheckIcon,
    XIcon,
    WebAppIcon,
    Card
  },

  props: {
    scanSubType: {
      type: String,
      required: true
    }
  },

  emits: ['changeSelectedScanSubType'],

  data: () => ({
    selectedScanSubType: '',
    apiQuickScanScopeList: [
      {
        title: 'Fingerprinting',
        isEnabled: true
      },
      {
        title: 'Transport Layer Security (TLS/SSL)',
        isEnabled: true
      },
      {
        title: 'Http Header',
        isEnabled: false
      },
      {
        title: 'Portscan',
        isEnabled: true
      }
    ],

    jsQuickScanScopeList: [
      {
        title: 'Fingerprinting',
        isEnabled: true
      },
      {
        title: 'Transport Layer Security (TLS/SSL)',
        isEnabled: true
      },
      {
        title: 'Http Header',
        isEnabled: true
      },
      {
        title: 'Portscan',
        isEnabled: true
      }
    ]
  }),

  watch: {
    selectedScanType(nw) {
      this.$emit('changeSelectedScanSubType', nw);
    },
  },

  mounted() {
    this.selectedScanSubType = this.scanSubType;
  },


  methods: {
    onChangeSelectedScanSubType(payload) {
      this.selectedScanSubType = payload;
    },
  }
}
</script>