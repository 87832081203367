<template>
  <div class="w-full mb-8">
    Set your scan target details
  </div>

  <div class="flex flex-col gap-2 justify-center">
    <Input
      :value="title"
      placeholder="Title"
      @input="title = $event.target.value"
    />

    <div class="flex flex-row gap-2">
      <Input
        :value="protocol"
        placeholder="Protocol"
        @input="protocol = $event.target.value"
      />

      <Input
        :value="url"
        class="w-full"
        placeholder="URL or IPv4 Address"
        @input="url = $event.target.value"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input.vue';

export default {
  components: {
    Input
  },

  data: () => ({
    title: 'asssd',
    protocol: '',
    url: '',
  }),
}
</script>