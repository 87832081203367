<template>
  <div class="flex flex-col">
    <div class="flex mb-5">
      <h1 class="text-xl font-semibold text-gray-900">
        SCANS
      </h1>
    </div>

    <Card>
      <template #content>
        <div class="flex flex-col">
          <!-- TODO: Burası düzenlenecek -->
          <div class="flex flex-row justify-between mb-8">
            <div
              v-for="step in scanType"
              :key="step.step"
              class="flex flex-row gap-2"
            >
              <div
                class="flex rounded-full h-6 w-6 items-center justify-center"
                :class="{'bg-blue-500': step.isDone, 'bg-gray-500': !step.isDone}"
              >
                <span class="text-white"> {{ step.step }} </span>
              </div>

              <div class="font-semibold">
                {{ step.title }}
              </div>
            </div>
          </div>

          <!-- headers -->

          <div
            v-if="currentStep > 3"
            class="w-full mb-8"
          >
            <div v-if="selectedScanType === 'Web Application'">
              <div v-if="currentStep === 4">
                <span>Set your scan target scope</span>
              </div>

              <div v-if="currentStep === 4">
                <span>We are running scanners, which could put a strain on the scanned system. In addition, some of these scanners should not be executed in a production environment. Therefore we advise choosing the scan scope carefully.</span>

                <ul class="list-disc ml-10 mt-3">
                  <li>If you select Quick Scan, we will execute only non-invasive scanners.</li>
                  <li>If you chose Full Scan, we would execute all of our scanners. You can find more information on setting up a full scan in our wiki article.</li>
                </ul>
              </div>
            </div>

            <div v-else-if="selectedScanType === 'Api'">
              <div v-if="currentStep === 4">
                <span>Please choose a distinctive name for your scan target and provide the protocol and URL or IPv4 address of the system to be scanned.</span>
              </div>

              <div v-if="currentStep === 5">
                <span>We are running scanners, which could put a strain on the scanned system. In addition, some of these scanners should not be executed in a production environment. Therefore we advise choosing the scan scope carefully.</span>

                <ul class="list-disc ml-10 mt-3">
                  <li>If you select Quick Scan, we will execute only non-invasive scanners.</li>
                  <li>If you chose Full Scan, we would execute all of our scanners. You can find more information on setting up a full scan in our wiki article.</li>
                </ul>
              </div>
            </div>
          </div>

          <!-- step 1 -->

          <Step1
            v-if="currentStep === 1"
            :scan-type="selectedScanType"
            @changeSelectedScanType="handleSelectedScanType"
          />

          <Step2
            v-if="currentStep === 2"
            :scan-sub-type="selectedScanSubType"
            @changeSelectedScanSubType="handleSelectedScanSubType"
          />

          <Step3
            v-else-if="currentStep === 3 || currentStep === 4 && selectedScanType === 'Api'"
          />

          <Step4
            v-else-if="currentStep === 4 && selectedScanType === 'Web Application' || currentStep === 5 && selectedScanType === 'Api'"
            :scan-sub-type="selectedScanSubType"
            @changeSelectedScanSubType="handleSelectedScanSubType"
          />
        </div>
      </template>

      <template #footer>
        <div class="flex flex-row justify-between w-full">
          <Button @btnClick="prevStep">
            <template #default>
              <div class="inline-flex flex-row items-center">
                <span>
                  Back
                </span>
              </div>
            </template>
          </Button>

          <div class="flex flex-row gap-5">
            <!-- TODO: Are u sure dialog expected -->
            <Button @btnClick="$router.push({ name: 'Scans' })">
              <div class="text-center">
                Cancel
              </div>
            </Button>

            <Button @btnClick="nextStep">
              <template #default>
                <div
                  class="text-center"
                >
                  Continue
                </div>
              </template>
            </Button>
          </div>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';
import Button from '@/components/Button.vue';

import Step1 from '@/components/wizard/general/Step1.vue';
import Step2 from '@/components/wizard/general/Step2.vue';
import Step3 from '@/components/wizard/general/Step3.vue';
import Step4 from '@/components/wizard/general/Step4.vue';

export default {
  components: {
    Card,
    Button,
    Step1,
    Step2,
    Step3,
    Step4
  },

  data: () => ({
    selectedScanType: 'Web Application',
    selectedScanSubType: 'Javascript Application',
    currentStep: 1,
    webApplicationSteps: [
      {
        step: 1,
        title: 'Select Type',
        isDone: true,
      },
      {
        step: 2,
        title: 'Select Sub Type',
        isDone: false,
      },
      {
        step: 3,
        title: 'Provide Details',
        isDone: false,
      },
      {
        step: 4,
        title: 'Select Scope',
        isDone: false,
      }
    ],

    apiSteps: [
      {
        step: 1,
        title: 'Select Type',
        isDone: true,
      },
      {
        step: 2,
        title: 'Select Sub Type',
        isDone: false,
      },
      {
        step: 3,
        title: 'Provide Details',
        isDone: false,
      },
      {
        step: 4,
        title: 'Provide API Specification',
        isDone: false,
      },
      {
        step: 5,
        title: 'Select Scope',
        isDone: false,
      }
    ],
  }),

  computed: {
    scanType() {
      if (this.selectedScanType === 'Web Application') {
        return this.webApplicationSteps;
      } else {
        return this.apiSteps;
      }
    },
  },

  methods: {
    handleSelectedScanType(scanType) {
      this.selectedScanType = scanType;
    },

    handleSelectedScanSubType(scanSubType) {
      this.selectedScanSubType = scanSubType;
    },

    nextStep() {
      if (this.selectedScanType === 'Web Application') {
        if (this.currentStep < this.webApplicationSteps.length) {
          this.currentStep = this.currentStep + 1;
          this.webApplicationSteps.forEach((webAppStep, index) => {
            if (webAppStep.step === this.currentStep) {
              this.webApplicationSteps[index].isDone = true;
            }
          });
        }
      } else {
        if (this.currentStep < this.apiSteps.length) {
          this.currentStep = this.currentStep + 1;
          this.apiSteps.forEach((webAppStep, index) => {
            if (webAppStep.step === this.currentStep) {
              this.apiSteps[index].isDone = true;
            }
          });
        }
      }
    },

    prevStep() {
      if (this.selectedScanType === 'Web Application') {
        if (this.currentStep > 1) {
          this.webApplicationSteps.forEach((webAppStep, index) => {
            if (webAppStep.step === this.currentStep) {
              this.webApplicationSteps[index].isDone = false;
            }
          });
          this.currentStep = this.currentStep - 1;
        }
      } else {
        if (this.currentStep > 1) {
          this.apiSteps.forEach((webAppStep, index) => {
            if (webAppStep.step === this.currentStep) {
              this.apiSteps[index].isDone = false;
            }
          });
          this.currentStep = this.currentStep - 1;
        }
      }
    }
  }
}
</script>
